<template>
  <div class="page">
    <div class="page-header">
      <h3 class="page-title">Пособия</h3>
      <div class="button">
        <SvButton primary @click="activeForm = 'create'">Создать</SvButton>
      </div>
    </div>

    <Filters
        :similar="filter.similar"
        :loading="loading"
        @changeSimilar="filterBy('similar', $event)"/>

    <div class="page-data">
      <Table
          :items="books"
          :fields="tableFields"
          :loading="loading"
          @rightClick="startUpdate"/>

      <Create
          v-if="activeForm === 'create'"
          @close="close()"/>

      <Update
          v-else-if="activeForm === 'update'"
          :data="selected"
          @close="close()"/>
    </div>

    <SvScrollTrigger @triggered="fetchNextBooks"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {SvButton, SvScrollTrigger} from "@/components/default";

import Table from "@/components/table/Table";

import Filters from '@/pages/books/filters/Filters';
import Create from '@/pages/books/forms/Create';
import Update from '@/pages/books/forms/Update';

export default {
  name: "Books",
  components: {
    SvButton,
    SvScrollTrigger,
    Table,
    Filters,
    Create,
    Update
  },
  data() {
    return {
      tableFields: [
        {
          header: true,
          prop: "name",
          label: "Название",
        },
      ],
      activeForm: "",
    }
  },
  methods: {
    ...mapActions("books", [
      "fetchBooks",
      "fetchNextBooks",
      "filterBooks",
      "findBook",
      "resetState",
    ]),

    filterBy(key, value) {
      this.filterBooks({key: key, value: value})
    },

    async startUpdate(bookID) {
      await this.findBook(bookID).then(() => {
        this.activeForm = "update"
      })

      if (this.$route.query.id !== bookID) {
        await this.$router.push(`/books?id=${bookID}`)
      }
    },

    close() {
      if (this.$route.query.id) {
        this.$router.push(`/books`)
      }

      this.activeForm = ""
    }
  },
  computed: {
    ...mapGetters("books", [
      "books",
      "selected",
      "filter",
      "loading",
    ]),
  },
  created() {
    this.fetchBooks().catch(err => {
      console.error(err)
    })

    if (this.$route.query.id) {
      this.startUpdate(Number(this.$route.query.id))
    }
  },
  unmounted() {
    this.resetState()
  }
}
</script>

<style lang="scss" scoped>
.button {
  margin: auto 0;
}
</style>
