<template>
  <div class="filters">
    <SvInput
        class="filters-field search"
        placeholder="Поиск"
        :loading="loading"
        :modelValue="similar"
        @input="changeSimilar"/>
  </div>
</template>

<script>
import {SvInput} from "@/components/default";

export default {
  name: "Filter",
  components: {
    SvInput,
  },
  emits: [
    "changeSimilar",
  ],
  props: {
    similar: {type: String, required: true},
    loading: {type: Boolean, default: false},
  },
  methods: {
    changeSimilar(evt) {
      this.$emit("changeSimilar", evt)
    },
  },
}
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: flex-end;
  padding-top: 4px;
  padding-bottom: 16px;

  &-field {
    height: 36px;
    margin-right: 12px;

    &.search {
      width: 400px;
    }
  }
}
</style>
